import React from 'react';
import { createRoot } from 'react-dom/client';

import { Root } from '~/src';
import App from '~/src/components/App';

const container = document.getElementById('app');
const root = container && createRoot(container);
root &&
  root.render(
    <Root>
      <App />
    </Root>,
  );
